@use 'components/css-variables';
@use 'components/site-navigation';
@use 'components/navigation';
@use 'components/btn';
@use 'components/testimonial';

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    color: hsl(var(--twc-brand-primary));
}

a {
    color: inherit;
}

figure {
    margin: 0;
}

img {
    max-width: 100%;
}

.bg-off-blue {
    background-color: hsl(var(--twc-brand-primary));
}

.bg-coral {
    background-image: linear-gradient(90deg, hsl(var(--twc-brand-secondary)) 0%, hsl(var(--twc-brand-secondary-alt))  100%);
}

.bg-coral-dark {
    background-image: linear-gradient(90deg, hsl(var(--twc-brand-secondary-dark)) 0%, hsl(var(--twc-brand-secondary-alt))  100%);
}

.bg-teal {
    background-color: hsl(var(--twc-bright-teal));
}

.bg-white {
    background-color: var(--pure-white);
}

.bg-brand-blue {
    background-color: hsl(var(--twc-brand-primary));
}

.bg-stone {
    background-color: var(--stone-grey);
}

.color-white {
    color: var(--pure-white);
}

.color-brand-primary {
    color: hsl(var(--twc-brand-primary));
}


.site-logo {
    max-width: 200px;
    width: 100%;
}

.cms {
    p {
        margin-bottom: 1em;
    }
}

.banner-image::part(sustainable-image) {
    aspect-ratio: 1517 / 672;
}

.sustainable-image::part(sustainable-image) {
    width: 100%;
    object-fit: cover;
    height: 100%;
}

.rectangle-image::part(sustainable-image) {
    aspect-ratio: 16 / 9;
}

.page-container {
    max-width: 1044px;
}

.post-triangle {
    position: absolute;
    bottom: -1em;
    left: -1em;
    border-width: 0.5em;
    border-style: solid;
    border-left-color: transparent;
    border-bottom-color: transparent;
}

.slick-arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 100;
}

.slick-next {
    right: -1em;

    @media(min-width: 1024px) {
        right: -2em;
    }
}

.slick-prev {
    left: -1em;

    @media(min-width: 1024px) {
        left: -2em;
    }
}


.slick-dots {
    display: flex;
    justify-content: center;
    padding: 1em;
    flex-wrap: wrap;

    li {
        border-radius: 100%;
        background-color: white;
        height: 20px;
        width: 20px;
        margin: 0 5px;
    }

    .slick-active {
        background-color: hsl(var(--twc-brand-secondary));
    }

    button {
        display: none;
    }
}


.testimonial-slider {
    max-width: 50rem;
    margin: 0 auto;

    .slick-next {
        @media(min-width: 1024px) {
            right: 138px;
        }
    }

    .slick-prev {
        @media(min-width: 1024px) {
            left: 138px;
        }
    }

    .slick-next svg,
    .slick-prev svg {
        width: 15px;
    }

    @media(min-width: 1024px) {

        .slick-list {
            padding: 0 150px !important;

            &:before {
                content: "";
                background: linear-gradient(90deg, hsl(var(--twc-brand-secondary)) 0%, #0000 20%, #0000 80%, hsl(var(--twc-brand-secondary))  100%);
                position: absolute;
                inset: 0;
                z-index: 100;
            }
        }

        .slick-slide .testimonial_copy {
            opacity: 0;
        }

        .slick-slide .testimonial:before {
            display: none;
        }


        .slick-active .testimonial_copy {
            opacity: 1;
        }

        .slick-active .testimonial:before {
            display: block;
        }


    }


}

.slider-dots {
    .slick-dots {
        li {
            background-color: transparent;
            border: 1px solid var(--pure-white);
            height: 10px;
            width: 10px;

        }

        .slick-active {
            background-color: var(--pure-white);
        }
    }
}

.testimonial-slider--arrows-white,
.slider--arrows-white {
    svg {
        stroke: white;
    }
}

.text-heading {
    border-left: 5px solid hsl(var(--twc-brand-secondary));
    padding-left: 0.5em;
    line-height: 1;
}

.text-heading--blue {
    border-left-color: hsl(var(--twc-brand-primary));
}

.form-drop-shadow {
    box-shadow: -3px 3px 6px #000;
}

.soft-shadow {
    box-shadow: 3px 5px 10px #00000025;
}

.link {
    &:hover {
        text-decoration: underline;
    }
}

.link--coral {
    &:hover {
        color: hsl(var(--twc-brand-secondary));
    }
}

.blog-layout {
    @media (min-width: 1024px) {
        display: grid;
        grid-template-columns: 54% 42%;
        grid-template-rows: 48% 48%;
        grid-gap: 4%;
    }
}

.blog-layout:not(.slick-slider) {

    .blog-layout_1,
    .blog-layout_2 {
        margin: 0 0 2em;
    }

    @media (min-width: 1024px) {

        .blog-layout_1,
        .blog-layout_2 {
            margin: 0;
        }
    }
}

.blog-layout_1 {
    grid-row: 1 / span 3;
    grid-column: 1;
    padding: 0 1em;

    @media (min-width: 1024px) {
        padding: 0;
    }
}

.blog-layout_2 {
    grid-row: 1;
    grid-column: 2;
    padding: 0 1em;

    @media (min-width: 1024px) {
        padding: 0;
    }
}

.blog-layout_3 {
    grid-row: 2;
    grid-column: 2;
    padding: 0 1em;

    @media (min-width: 1024px) {
        padding: 0;
    }
}


.curve {
    position: absolute;
    left: 0;
    width: 100%;
    z-index: 100;
}

.curve--top {
    top: -1px; // sub pixel rendering
    transform: rotate(-180deg);
}

.curve--bottom {
    bottom: -1px;

}

.image-overlay {
    background-color: rgba(#000, 0.5);
}

.border-coral {
    border-color: hsl(var(--twc-brand-secondary));
}

.border-brand-primary {
    border-color: hsl(var(--twc-brand-primary));
}


.faq {
    summary {
        display: flex;
        justify-content: space-between;
        list-style-type: none;

        &:after {
            content: "+";
            top: 0;
            right: 0;
            background-color: hsl(var(--twc-brand-secondary));
            border-radius: 2px;
            color: white;
            height: 30px;
            width: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
        }

    }

    &[open]>summary {
        &:after {
            content: "-";
        }
    }
}


.flex-w-25 {
    margin: 10px;

    @media(min-width: 1024px) {
        flex: 0 0 calc(25% - 20px);
    }
}

.flex-w-33 {
    margin: 10px;

    @media(max-width: 768px) {
        width: 100%;
    }

    @media(min-width: 768px) {
        flex: 0 0 calc(33% - 20px);
    }
}

.border-coral-bottom {
    border-bottom-color: hsl(var(--twc-brand-secondary));
}

.border-coral-left {
    border-left-color: hsl(var(--twc-brand-secondary));
}

.clip-path {
    @media (min-width: 1024px) {
        clip-path: url(#wavy-clip-path);
    }
}

.clip-path--mobile {
    clip-path: url(#wavy-clip-path);
}

.pagination {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.pagination_item {
    background-color: var(--stone-grey);
    border-radius: 100%;
    padding: 5px;
    margin: 5px;
    height: 30px;
    width: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.pagination_item:hover,
.pagination_item--active {
    background-color: hsl(var(--twc-brand-primary));
    color: white;
}

.fill-white {
    fill: var(--pure-white);
}

.fill-brand-secondary {
    fill: hsl(var(--twc-brand-primary));
}

.fill-brand-secondary {
    fill: hsl(var(--twc-brand-secondary));
}

.card-band::after {
    content:'';
    height: 15px;
    @apply w-full block bg-coral-dark rounded-br rounded-bl;
}

.card-band-alt::after {
    content:'';
    height: 15px;
    @apply w-full block bg-brand-tertiary-dark rounded-br rounded-bl;
}

.bg-brand-tertiary-dark {
    background-image: linear-gradient(90deg, hsl(var(--twc-brand-tertiary-dark)) 0%, hsl(var(--twc-brand-tertiary))  100%);
}

.lg-mt-16 {
    @media (width >= 1024px) {
        margin-top: 16rem;
    }
}